import { MerFurTableDTO } from '../../model/dto-module'
import { MerStatusEnum, ReportTypeEnum } from '../../enums/mer-status.enum'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { CodeDescription, TableAction, TableDetailCell, TableHeaderInterface } from '../../model/models-module'
import { convertDate, handleFile, parseParams } from '../../utils/CommonUtils'
import { languages, status, typeOfReport } from './../../configs/data'

import CustomFlag from '../../components/common/flag/flag'
import DataTable from '../../components/common/datatable/Datatable'
import { ReactComponent as DeleteIcon } from './../../assets/icons/delete-icon.svg'
import { ReactComponent as EditIcon } from './../../assets/icons/edit.svg'
import FURModal from './fur/new-fur-modal'
import FollowUpReportService from '../../services/follow-up-report.service'
import LoadingComponent from '../../components/common/loading/loading'
import MERModal from './mer/new/new-mer'
import { ReactComponent as MerIcon } from './../../assets/icons/icono-mer.svg'
import MutualEvaluationService from '../../services/mutual-evaluation.service'
import { ReactComponent as NewFurIcon } from './../../assets/icons/new-fur-icon.svg'
import { ReactComponent as FurTableIcon } from './../../assets/icons/fur-table-icon.svg'
import { ReactComponent as MerTableIcon } from './../../assets/icons/mer-table-icon.svg'
import { ReactComponent as NewMerIcon } from './../../assets/icons/new-mer-icon.svg'
import ProgressBar from '../../components/common/progress-bar/progress-bar'
import { ReactComponent as PublishIcon } from './../../assets/icons/publish.svg'
import { SvgIcon } from '@mui/material'
import { ReactComponent as DownloadIcon } from './../../assets/icons/download-icon.svg'
import { ReactComponent as UnPublishIcon } from './../../assets/icons/unpublish.svg'
import { ReactComponent as ViewIcon } from './../../assets/icons/eye.svg'
import { merMenuConfig } from '../../configs/mer-menu.config'
import styles from './mer-fur.module.css'
import ConfirmDialog from '../../components/common/confirm-dialog/confirmDialog'
import { useDefaultData } from '../../contexts/default-data-context'
import { useLanguage } from '../../contexts/language-context'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserService from '../../services/UserService'
import { EDITOR_ROLE, USER_ADMIN_ROLE } from '../../components/common/constant/constants'
import { ChildrenTypeEnum } from '../../configs/data'
import { ReactComponent as ExpandIconDefault } from '../../assets/icons/expand-icon.svg'
import { ReactComponent as CollapseIconDefault } from '../../assets/icons/collapse-icon.svg'
import CustomAccordionFilter, { AccordionGroupData } from '../../components/common/accordionV2/accordion-v2'
import { useErrorContext } from '../../contexts/error-context'

export default function MerFur() {


  const { language: appLanguage } = useLanguage()

  const { t } = useTranslation('global')
  const [initialValues, setInitialValues] = useState<Map<string, any>>(undefined)
  const [openMERModal, setOpenMERModal] = useState(false)
  const [openFURModal, setOpenFURModal] = useState(false)
  const {
    countries,
    assessmentBodies,
    merFurYears,
    rounds,
    loading: merDataLoading,
  } = useDefaultData()
  const { setError: setContextError } = useErrorContext()
  const [merService] = useState<MutualEvaluationService>(MutualEvaluationService.getInstance(setContextError))
  const furService = FollowUpReportService.getInstance(setContextError)
  const [hasDataFilter, setHasDataFilter] = useState(false)
  const [count, setCount] = useState<number>(0)
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [dataHeader, setDataHeader] = useState<TableHeaderInterface[]>([])
  const [filtersData, setFiltersData] = useState<Map<string, any>>(new Map<string, any>())
  const [dataTo, setDataTo] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [dataOffset, setDataOffset] = useState<number>(0)
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [sort] = useState<Map<string, string>>(new Map())
  const [initSort] = useState<Map<string, string>>(new Map([['countryName', 'ASC'],
  ['type', 'DESC'],
  ['date', 'DESC']]))
  const [isAdmin] = useState<boolean>(UserService.hasRole([USER_ADMIN_ROLE]));
  const [isEditor] = useState<boolean>(UserService.hasRole([EDITOR_ROLE]));
  const [confirm, setConfirm] = useState<any>(null)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [accordionData, setAccordionData] = useState<AccordionGroupData[]>();

  console.log('is editor', isEditor)
  const headerStyle = {
    minWidth: '150px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    fontSize: '18px',
    color: '#003479',
    textAlign: 'left',
    paddingTop: '15px',
    paddingBottom: '15px',
  }
  const colStyle = {
    border: '1px solid red'
  }
  const handleOpenMERModal = () => setOpenMERModal(true)
  const handleOpenFURModal = () => setOpenFURModal(true)

  const navigate = useNavigate()
  const onNavigate = (path: string) => {
    navigate(path)
  }

  const findByQuery = useCallback(({ params = filtersData, lang = appLanguage, limit = dataTo, offset = dataOffset, pSort = sort }: { params?: Map<string, string[]>, lang?, limit?, offset?, pSort?} = { params: filtersData, limit: dataTo, offset: dataOffset, pSort: sort }) => {
    setShowSpinner(true);
    // Params
    const args = parseParams(params);
    localStorage.setItem('merFilters', JSON.stringify({ params: Object.fromEntries(params), limit: limit, offset: offset }));
    // Sort
    let sortArgs = ''
    if (pSort.size > 0) {
      pSort.forEach((value) => {
        if (sortArgs === '') {
          sortArgs += value
        } else {
          sortArgs += `,${value}`
        }
      })
    }

    merService.findByQuery({ args, lang: lang ?? appLanguage, limit, offset, sort: sortArgs })
      .then((data) => {
        setCount(data.count);
        setDataShow(renderResultsTable(data.results, offset, params, limit, pSort))
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }, [filtersData, appLanguage, dataTo, dataOffset, sort]);

  const publishMer = async (item: MerFurTableDTO, offset: number, filtersData, dataTo, sort) => {
    try {
      setShowSpinner(true)
      if (item.type === ReportTypeEnum.FUR) {
        await furService.publishFur(appLanguage, item.id, item.language)
      } else {
        await merService.publishMer(appLanguage, item.id, item.language)
      }
      findByQuery({ offset, params: filtersData, limit: dataTo, pSort: sort })
    } catch (e) {
      setShowSpinner(false)
      // setContextError(e)
    }
  }

  const unPublishMer = async (item: MerFurTableDTO, offset: number, filtersData, dataTo, sort) => {
    try {
      setShowSpinner(true)
      if (item.type === ReportTypeEnum.FUR) {
        await furService.unPublishFur(appLanguage, item.id, item.language)
      } else {
        await merService.unPublishMer(appLanguage, item.id, item.language)
      }
      findByQuery({ offset, params: filtersData, limit: dataTo, pSort: sort })
    } catch (e) {
      setShowSpinner(false)
    }
  }

  const goUpdateItem = (item: MerFurTableDTO) => {
    let baseUrl = 'mer'
    if (item.type === ReportTypeEnum.FUR) {
      baseUrl = 'fur'
    }
    onNavigate(`/main/${baseUrl}/update/${item.id}/${item.language}`)
  }

  const downloadPdf = async (item: MerFurTableDTO) => {
    try {
      setShowSpinner(true)
      const result =
        item.type === ReportTypeEnum.FUR
          ? await furService.downloadFurPDF(appLanguage, item.id, item.language)
          : await merService.downloadMutualEvaluationPDF(appLanguage, item.id, item.language)
      const filename = `${item?.countryName}-${item?.date}-${item?.round
        }-${item.language.toUpperCase()}`
      handleFile(result?.data, `${filename}.pdf`)
    } catch (error) {
      // setContextError(error)
      console.log(error)
    } finally {
      setShowSpinner(false)
    }
  }

  const previewFile = (item: MerFurTableDTO) => {
    const url = item.type === ReportTypeEnum.FUR ? 'fur' : 'mer'
    onNavigate(`/main/${url}/view-file/${item.id}/${item.language}`)
  }

  const onDelete = async (item: MerFurTableDTO, offset: number, filtersData, dataTo, sort) => {

    setOpenConfirm(true)
    const newConfirm = {
      title: t('dialog.delete.title'),
      content: t('dialog.delete.content'),
      cb: async () => {
        try {
          setShowSpinner(true)
          if (item.type === ReportTypeEnum.MER) {
            await merService.deleteMer(appLanguage, item.id, item.language)
          } else {
            await furService.deleteFur(appLanguage, item.id, item.language)
          }
          findByQuery({ offset, params: filtersData, limit: dataTo, pSort: sort })
        } catch (e) {
          setShowSpinner(false)
          // setContextError(e)
        }
      }
    }
    setConfirm(newConfirm)

  }
  const getActionsByStatus = useCallback((status: string, offset: number, filtersData, dataTo, sort): TableAction[] => {
    const viewAction = new TableAction(t('buttons.view'), (r) => { previewFile(r) },
      <SvgIcon component={ViewIcon} />
    )
    const download = new TableAction(t('buttons.download'), (r) => { downloadPdf(r) },
      <DownloadIcon height={24} width={24} />

    )

    const editAction = new TableAction(
      t('buttons.edit'),
      (r) => {
        goUpdateItem(r)
      },
      (
        <SvgIcon component={EditIcon} />
      ),
    )
    const publishAction = new TableAction(
      t('buttons.publish'),
      (r) => publishMer(r, offset, filtersData, dataTo, sort),
      (
        <SvgIcon component={PublishIcon} />
      ),
    )

    const unPublishAction = new TableAction(
      t('buttons.unpublish'),
      (r) => unPublishMer(r, offset, filtersData, dataTo, sort),
      (
        <SvgIcon component={UnPublishIcon} />
      ),
    )
    const deleteAction = new TableAction(
      t('buttons.delete'),
      (r) => {
        onDelete(r, offset, filtersData, dataTo, sort)
      },
      (
        <SvgIcon component={DeleteIcon} />
      ),
    )
    if (status === MerStatusEnum.DRAFT || status === MerStatusEnum.UNPUBLISHED) {
      if (isAdmin) {
        return [viewAction, editAction, publishAction, deleteAction, download]
      } else if (isEditor) {
        return [viewAction, editAction, download]
      }
      else {
        return [viewAction, download]
      }
    }

    if (status === MerStatusEnum.PUBLISHED) {
      if (isAdmin) {
        return [viewAction, unPublishAction, download]
      }
      else if (isEditor) {
        return [viewAction, download]
      }
      else {
        return [viewAction, download]
      }
    }

    return []
  }, [appLanguage])

  const renderResultsTable = useCallback((list: any[], offset: number, filtersData, dataTo, sort) => {
    return list.map((el, i) => {
      return new TableDetailCell(
        el,
        getActionsByStatus(el.statusCode, offset, filtersData, dataTo, sort),
        new Map<string, React.ReactNode>([
          ['countryName',
            <CustomFlag key={i} code={el.countryCode} flagName={el.countryName} />
          ],
          [
            'type',
            <span onClick={() => { previewFile(el) }} key={i} className={`${styles.merTableIcon} c-pointer`}>
              {el.type === ReportTypeEnum.MER ? <MerTableIcon /> : <FurTableIcon />}
              {el.type}
            </span>,
          ],
          ['date', <span key={i}> {convertDate(el.date, appLanguage)}</span>],
        ]),
      )
    })
  }, [appLanguage])

  useEffect(() => {
    setHasDataFilter(false)
    // sort.clear()
    if (!merDataLoading) {
      if (!!rounds && !!assessmentBodies && !!countries && !!merFurYears) {

        setAccordionData([

          {
            customKey: merMenuConfig[0].customKey,
            parentDictionaryKey: merMenuConfig[0].summary,
            summary: t(merMenuConfig[0].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: assessmentBodies.map(ab => new CodeDescription(ab.id, ab.id, ab.assessmentBodyName))
          },
          {
            customKey: merMenuConfig[1].customKey,
            parentDictionaryKey: merMenuConfig[1].summary,
            summary: t(merMenuConfig[1].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: rounds.map(r => new CodeDescription(r.id, String(r.roundCode), r.roundValue))
          },
          {
            customKey: merMenuConfig[2].customKey,
            parentDictionaryKey: merMenuConfig[2].summary,
            summary: t(merMenuConfig[2].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: merFurYears.map(r => new CodeDescription(r.year, r.year.toString(), r.year.toString()))
          },
          {
            customKey: merMenuConfig[3].customKey,
            parentDictionaryKey: merMenuConfig[3].summary,
            summary: t(merMenuConfig[3].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: countries.map(c => new CodeDescription(c.id, c.countryCode, c.countryName))
          },
          {
            customKey: merMenuConfig[4].customKey,
            parentDictionaryKey: merMenuConfig[4].summary,
            summary: t(merMenuConfig[4].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: typeOfReport.map((r, i) => {
              return new CodeDescription(i, r.value, t(r.name))
            })
          },
          {
            customKey: merMenuConfig[5].customKey,
            parentDictionaryKey: merMenuConfig[5].summary,
            summary: t(merMenuConfig[5].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: languages.map((r, i) => {
              return new CodeDescription(i, r.value, t(r.name))
            })
          },
          {
            customKey: merMenuConfig[6].customKey,
            parentDictionaryKey: merMenuConfig[6].summary,
            summary: t(merMenuConfig[6].summary),
            elementType: ChildrenTypeEnum.CHECKBOX,
            expandIcon: ExpandIconDefault,
            collapseIcon: CollapseIconDefault,
            data: status.map((r, i) => {
              return new CodeDescription(i, r.value, t(r.name))
            })
          },
        ]);
        setHasDataFilter(true)
      } else {
        setHasDataFilter(false)
      }
    }
  }, [appLanguage, merDataLoading])

  useMemo(() => {
    setDataHeader([
      {
        label: t('merFurList.table.columns.country'),
        key: 'countryName',
        sorteable: true,
        headerStyle,
        sort: 'ASC',
        // colStyle: {
        //   ...colStyle,
        //   margin: 0,
        //   padding: 0
        // }
      },
      {
        label: t('merFurList.table.columns.language'),
        key: 'language',
        sorteable: true,
        headerStyle,
        // colStyle: {
        //   ...colStyle,
        //   padding: '2px 0px 2px 0px'
        // }

      },
      {
        label: t('merFurList.table.columns.report'),
        key: 'type',
        sorteable: true,
        headerStyle,
        sort: 'DESC',
        // colStyle: {
        //   ...colStyle,
        //   margin: 0,
        //   padding: 0
        // }
      },
      {
        label: t('merFurList.table.columns.assessmentBody'),
        key: 'assessmentBodyName',
        sorteable: true,
        headerStyle,
        // colStyle
      },
      {
        label: t('merFurList.table.columns.round'),
        key: 'round',
        sorteable: true,
        headerStyle,
        // colStyle
      },
      {
        label: t('merFurList.table.columns.date'),
        key: 'date',
        sorteable: true,
        headerStyle: {
          ...headerStyle,
          minWidth: '130px',
        },
        sort: 'DESC',
        // colStyle
      },
      {
        label: t('merFurList.table.columns.status'),
        key: 'status',
        sorteable: true,
        headerStyle: {
          ...headerStyle,
          minWidth: '130px',
        },
        // colStyle
      },
    ])
  }, [appLanguage])

  useMemo(() => {
    const { params: auxParams, limit, offset } = JSON.parse(localStorage.getItem('merFilters')) ?? {};
    const params = new Map();
    sort.set('countryName', 'countryName:ASC');
    sort.set('type', 'type:DESC');
    sort.set('date', 'date:DESC');
    for (const prop in auxParams) {
      params.set(prop, auxParams[prop]);
    }
    setFiltersData(params ?? new Map());
    setDataOffset(offset ?? 0);
    setDataTo(limit ?? 10);
    setPage((offset ?? 0) + 1);
    setInitialValues(params ?? new Map())
    findByQuery({ params: params ?? new Map(), lang: appLanguage, limit: limit ?? 10, offset: offset ?? 0 });
  }, [appLanguage])

  return (
    <>
      {open && <FURModal open={openFURModal} setOpen={setOpenFURModal} />}
      {open && <MERModal open={openMERModal} setOpen={setOpenMERModal} />}
      {confirm &&
        <ConfirmDialog
          content={confirm.content}
          open={openConfirm}
          title={confirm.title}
          setOpen={setOpenConfirm}
          cb={confirm.cb}
        />}

      <ProgressBar title='MERs & FURs' icon={MerIcon}>
        {
          isAdmin || isEditor ?
            <div className={`${styles.merButtons} d-flex right`}>
              <button className={`${styles.buttonBlue} mr-25`} onClick={handleOpenMERModal}>
                <NewMerIcon style={{ width: '28px', height: '28px' }} />
                {t('merFurList.buttons.newMer')}
              </button>
              <button className={`${styles.buttonLightBlue} mr-25`} onClick={handleOpenFURModal}>
                <NewFurIcon style={{ width: '28px', height: '28px' }} />
                {t('merFurList.buttons.newFur')}
              </button>
            </div>
            :
            null

        }
      </ProgressBar>
      <div className='main-content'>

        <CustomAccordionFilter
          data={accordionData}
          initialValues={initialValues}
          loadingData={!hasDataFilter || initialValues === undefined}
          onSelectableChange={(dataSelected) => {
            setFiltersData(dataSelected);
            setDataOffset(0);
            setPage(1);
            findByQuery({ params: dataSelected });
          }}
          withChips={true}
        />
        {/* <p>RefreshTable -{JSON.stringify(refreshTable)}</p> */}

        <div className='page-content'>
          <div className='table'>
            {!showSpinner ? (
              <div className={'container-abm-page'}>
                <div className={'table-templates-container'}>
                  <DataTable
                    headers={dataHeader}
                    rows={dataShow}
                    countItems={count}
                    rowsPerPage={dataTo}
                    hasActions={true}
                    // initSort={sort}
                    onPageSizeChange={(size) => {
                      setDataTo(size)
                      setDataOffset(0)
                      findByQuery({ params: filtersData, limit: size, offset: 0 });
                    }}
                    onPageChange={(newPage: number) => {
                      if (newPage !== page) {
                        setPage(newPage)
                        setDataOffset(newPage - 1);
                        findByQuery({ params: filtersData, offset: newPage - 1, limit: dataTo });
                      }
                    }}
                    // onSortChange={onSort}
                    pageSelected={page}
                    onSortChange={(key: string, order: string) => {
                      if (order === '') {
                        sort.delete(key)
                      } else {
                        sort.set(key, `${key}:${order}`)
                      }
                      setDataOffset(0);
                      setPage(1);
                      findByQuery({ pSort: sort })
                    }}
                    pageable={true}
                    className=' thinner'
                  ></DataTable>
                </div>
              </div>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
